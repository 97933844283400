<template>
  <div class="container-grid grid-3" v-if="documents.length">
    <document v-for="(document,index) in documents" :key="index" :title="document.title" :link="document.link" />
  </div>
</template>

<script>
import Document from "@/components/Document.vue";

export default {
  name: "DocumentList",
  components: {Document},
  props: {
    documents: Array,
    default: []
  }
}
</script>

<style scoped>

</style>